<script setup>
import { getMapBadgeProps } from '@@/components/Common/Badges/BadgeProps';

defineProps(getMapBadgeProps([
  'additionalClassNames',
  'backgroundColor',
  'borderColor',
  'color',
]));
</script>

<template>
  <Badge
    additional-class-names="background-color-saturated-blue"
    border-color="border-color-saturated-navy"
    color="tw-text-white"
    :padding-x="paddingX"
    :padding-y="paddingY"
    :shape="shape"
    :size="size"
  >
    <template #default>
      <slot>New</slot>
    </template>
  </Badge>
</template>
