<script setup>
import { getMapBadgeProps } from '@@/components/Common/Badges/BadgeProps';

defineProps(getMapBadgeProps([
  'additionalClassNames',
  'backgroundColor',
  'borderColor',
  'color',
]));
</script>

<template>
  <Badge
    :additional-class-names="$style.container"
    border-color="border-color-saturated-red"
    color="text-saturated-red-color"
    :padding-x="paddingX"
    :padding-y="paddingY"
    :shape="shape"
    :size="size"
  >
    <template #default>
      <slot>Hi-Res</slot>
    </template>
  </Badge>
</template>

<style module>
.container {
  background-color: white;
}

:global([data-theme="dark"]) .container {
  background-color: black;
}
</style>
