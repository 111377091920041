<template>
  <ul class="tw-flex tw-flex-wrap">
    <LayerSelectionItem
      v-for="layer in layers"
      :key="layer.display"
      :class="selectionWrapperClass(layer)"
      @click="handleLayerClick(layer.short_name)"
    >
      <div
        :class="thumbnailClass"
        :style="{ backgroundImage: `url(${layer.image_url})` }"
      >
        <div
          v-if="isLocked(layer)"
          class="tw-absolute tw-inset-0 tw-bg-black tw-opacity-50"
        />
        <font-awesome-icon
          v-if="isLocked(layer)"
          :class="lockIconClass"
          :icon="['fas', 'lock']"
        />
      </div>
      <div class="tw-h-full tw-flex tw-flex-col tw-justify-between tw-grow">
        <div>
          <h3 class="tw-font-bold tw-text-base">
            {{ layer.display }}
            <NewBadge
              v-if="layer.is_new"
              class="tw-ml-1 tw-relative tw-bottom-0.5"
            />
          </h3>
          <p
            :class="descriptionClass"
          >
            {{ layer.description }}
          </p>
        </div>
        <div class="tw-flex tw-justify-between tw-items-center">
          <ul
            v-if="showBadges"
            class="tw-list-none"
          >
            <li
              v-for="{ component, name, props } in getBadges(layer)"
              :key="name"
              class="tw-inline-block tw-mr-0.5 lg:tw-mr-1.5 last:tw-mr-0"
            >
              <component
                :is="component"
                v-bind="props"
              />
            </li>
          </ul>
          <NuxtLink
            v-if="layer.support_url"
            class="tw-leading-none"
            :href="layer.support_url"
            target="_blank"
            @click.stop
          >
            <font-awesome-icon
              :class="getInfoIconClass(layer)"
              :icon="['far', 'info-circle']"
            />
          </NuxtLink>
        </div>
      </div>
    </LayerSelectionItem>
  </ul>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { getStartFreeTrialUrl } from '@@/utils/LoginUtils';
import AllAccessBadge from '@@/components/Common/Badges/AllAccessBadge.vue';
import FreeBadge from '@@/components/Common/Badges/FreeBadge.vue';
import HighResBadge from '@@/components/Common/Badges/HighResBadge.vue';
import LoopableBadge from '@@/components/Common/Badges/LoopableBadge.vue';
import EstimatedBadge from '@@/components/Common/Badges/EstimatedBadge.vue';
import ProPreviewBadge from '@@/components/Common/Badges/ProPreviewBadge.vue';

export default {
  name: 'LayerSelectionControl',

  props: {
    currentLayer: {
      type: Object,
      default: null,
    },
    layers: {
      type: [Array, Object],
      required: true,
    },
    showBadges: {
      type: Boolean,
      default: false,
    },
    showLock: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  computed: {
    ...mapState(useUserStore, ['isAllAccess', 'isGuest']),

    descriptionClass() {
      return [
        'tw-w-full',
        'tw-font-normal',
        'tw-text-sm',
        'tw-pb-2.5',
      ];
    },

    lockIconClass() {
      return [
        'tw-absolute',
        'tw-block',
        'tw-inset-1/2',
        'tw-text-white',
        this.$style.lockIcon,
      ];
    },

    thumbnailClass() {
      return [
        'tw-flex-shrink-0',
        'tw-relative',
        'tw-w-14',
        'tw-h-14',
        'lg:tw-w-52',
        'lg:tw-h-32',
        'tw-mr-2.5',
        'tw-rounded-lg',
        'tw-overflow-hidden',
        'tw-bg-cover',
        'tw-bg-center',
        'tw-bg-no-repeat',
      ];
    },
  },

  methods: {
    ...mapActions(useMapStore, [
      'setCurrentBaseMap',
      'setIsLayerDrawerOpen',
    ]),

    getBadges(layer) {
      const badges = [];

      if (!this.isAllAccess) {
        if (layer.is_all_access) {
          badges.push({
            component: AllAccessBadge,
            name: 'AllAccessBadge',
            props: { large: true },
          });
        }
        else {
          badges.push({
            component: FreeBadge,
            name: 'FreeBadge',
          });
        }
      }

      if (layer.is_high_res) {
        badges.push({
          component: HighResBadge,
          name: 'HighResBadge',
        });
      }

      if (layer.is_animated) {
        badges.push({
          component: LoopableBadge,
          name: 'LoopableBadge',
        });
      }

      if (layer.is_estimated) {
        badges.push({
          component: EstimatedBadge,
          name: 'EstimatedBadge',
        });
      }

      if (layer.is_pro_preview) {
        badges.push({
          component: ProPreviewBadge,
          name: 'ProPreviewBadge',
        });
      }

      return badges;
    },

    getInfoIconClass(layer) {
      return layer === this.currentLayer ? 'text-lightest-color' : 'text-dark-color';
    },

    /**
     * Wait 250ms after closing the layer drawer before changing the base map so that the base map
     * is updated _after_ the layer drawer is closed.
     */
    handleLayerClick(shortName) {
      const isLayerAllAccess = () => {
        if (Array.isArray(this.layers)) {
          return this.layers.find((layer) => layer.short_name === shortName).is_all_access;
        }

        return this.layers[shortName].is_all_access;
      };

      if (!this.isAllAccess && isLayerAllAccess()) {
        const params = {
          isGuest: this.isGuest,
          query: {
            return_to: this.$route.path,
            source: 'map_layers',
          },
        };
        const url = getStartFreeTrialUrl(params);
        this.$router.push(url);

        return;
      }

      this.setIsLayerDrawerOpen(false);
      window.setTimeout(() => this.$emit('click', shortName), 250);
    },

    isLocked(layer) {
      return !!(this.showLock && layer.is_all_access && !this.isAllAccess);
    },

    selectionWrapperClass(layer) {
      const isCurrentLayer = layer === this.currentLayer;

      return [
        isCurrentLayer ? this.$style.currentSelection : undefined,
        isCurrentLayer ? 'tw-rounded-lg' : undefined,
      ];
    },
  },
};
</script>

<style module>
.currentSelection {
  background-color: var(--brand-border-color);
  color: white;
}

/*
 * NOTE: This class name is repeated in the selector to increase specificity so that this rule-set
 * takes precedence over the default FontAwesome styles.
 */
.lockIcon.lockIcon {
  height: 50%;
  margin-left: -25%;
  margin-top: -25%;
  width: 50%;
}

@media (min-width: 992px) {
  .lockIcon.lockIcon {
    height: 40%;
    margin-left: -10%;
    margin-top: -15%;
    width: 20%;
  }
}
</style>
