<template>
  <div
    ref="container"
    :class="containerClass"
    @mousedown="handleMouseDown"
    @mouseleave="handleMouseLeave"
    @mousemove="handleMouseMove"
    @mouseup="handleMouseUp"
  >
    <Pills
      class="tw-px-1 tw-py-1"
      :disable-hover="isMoving"
      :is-strong-button="true"
      :pills="pills"
      :selected-pill="selectedPill"
      :selected-option="selectedOption"
      :should-scroll-active-element-into-view="true"
      @click="handlePillClick"
    />
  </div>
</template>

<script>
/* eslint no-console: off */
import { mapActions, mapState } from 'pinia';
import { getStartFreeTrialUrl } from '@@/utils/LoginUtils';
import { Pill } from '@/components/Common/Pills.vue';

export default {
  name: 'OverlayFieldControl',

  /**
   * Allow for horizontal click to drag and scroll on desktop devices.
   * @see https://codepen.io/toddwebdev/pen/yExKoj
   */
  data() {
    return {
      isDown: false,
      isMoving: false,
      scrollLeft: null,
      startX: null,
    };
  },

  computed: {
    ...mapState(useUserStore, ['isAllAccess', 'isGuest']),

    ...mapState(useMapStore, {
      overlayFieldControlOptions: (state) => state.ui.overlayFieldControlOptions,
      overlayFieldControlValue: (state) => state.ui.overlayFieldControlValue,
    }),

    containerClass() {
      return [
        'tw-absolute tw-top-2.5 tw-z-10',
        'tw-w-full',
        'tw-overflow-x-auto tw-overflow-y-visible hide-scrollbar',
        this.isDown ? 'cursor-grab' : null,
      ];
    },

    pills() {
      /* eslint camelcase: off */
      return this.overlayFieldControlOptions
        ?.map(({ icon, is_all_access, label, options }) => {
          const isLocked = is_all_access && !this.isAllAccess;
          let pill;

          if (options) {
            pill = new Pill({
              icon: isLocked ? null : icon,
              isLocked,
              text: label,
              options: options.map((option) => ({
                icon: option.icon,
                isLocked: option.is_all_access && !this.isAllAccess,
                text: option.label,
              })),
            });
          }
          else {
            pill = new Pill({
              icon: isLocked ? null : icon,
              isLocked,
              text: label,
            });
          }

          return pill;
        });
    },

    selectedOption() {
      if (this.selectedPill && this.overlayFieldControlOptions[this.selectedPill].options) {
        return this
          .overlayFieldControlOptions[this.selectedPill]
          .options
          .findIndex((option) => option.value == this.overlayFieldControlValue);
      }

      return null;
    },

    selectedPill() {
      return this.overlayFieldControlOptions
        ?.findIndex((option, index) => {
          if (option.options) {
            if (option.options.find(({ value }) => value === this.overlayFieldControlValue)) {
              return index;
            }
          }

          return option.value === this.overlayFieldControlValue;
        });
    },
  },

  methods: {
    ...mapActions(useMapStore, ['setMapUiProperties']),

    handleMouseDown(e) {
      // console.log('OverlayFieldControl.handleMouseDown()');

      this.isDown = true;
      this.startX = e.pageX;
      this.scrollLeft = this.$refs.container.scrollLeft;
    },

    handleMouseLeave() {
      if (!this.isDown) {
        return;
      }

      // console.log('OverlayFieldControl.handleMouseLeave()');
      this.resetMouseTracking();
    },

    handleMouseMove(e) {
      if (!this.isDown) {
        return;
      }

      // Ignore the mousemove event if the mouse hasn't moved more than a few pixels from the
      // original mousedown location. Note that this will cause some mousemove events to get
      // dropped but that might actually result in a smoother drag animation! Eventually, as
      // the user continues dragging, the delta will be exceeded and the pills will be scrolled.
      //
      // SEE:
      // - https://github.com/cloudninewx/Projects/issues/842
      // - https://stackoverflow.com/questions/6042202/how-to-distinguish-mouse-click-and-drag#answer-59741870

      const delta = 6;
      const diffX = Math.abs(e.pageX - this.startX);

      if (diffX < delta) {
        // console.log(`OverlayFieldControl.handleMouseMove(): Ignore mousemove horizontal distance moved ${diffX}px is less than ${delta}px`);
        return;
      }

      // console.log(`OverlayFieldControl.handleMouseMove(): Horizontal distance moved = ${diffX}px`);

      this.isMoving = true;

      e.preventDefault();

      const x = e.pageX - this.$refs.container.offsetLeft;
      const walk = (x - this.startX) * 2;
      const scrollLeft = this.scrollLeft - walk;
      this.$refs.container.scrollLeft = scrollLeft;
    },

    handleMouseUp() {
      if (!this.isDown) {
        return;
      }

      // console.log('OverlayFieldControl.handleMouseUp()');
      this.resetMouseTracking();
    },

    handlePillClick($event, selectedPill, selectedOption) {
      if (selectedOption?.isLocked || selectedPill.isLocked) {
        const params = {
          isGuest: this.isGuest,
          query: {
            return_to: this.$route.path,
            source: 'map_overlay_field_control',
          },
        };
        const url = getStartFreeTrialUrl(params);
        this.$router.push(url);

        return;
      }

      let selectedOverlayFieldControlOption;

      this.overlayFieldControlOptions.forEach((option) => {
        if (selectedOverlayFieldControlOption) {
          return;
        }

        if (selectedOption) {
          if (!option.options) {
            return;
          }

          const foundOption = option.options.find(({ label }) => label === selectedOption.text);

          if (foundOption) {
            selectedOverlayFieldControlOption = foundOption;
          }

          return;
        }

        if (option.label === selectedPill.text) {
          selectedOverlayFieldControlOption = option;
          return;
        }

        return;
      });

      const overlayFieldControlValue = selectedOverlayFieldControlOption.value;
      this.setMapUiProperties({ overlayFieldControlValue });
    },

    resetMouseTracking() {
      this.isDown = false;
      this.isMoving = false;
      this.startX = null;
      this.startY = null;
    },
  },
};
</script>
